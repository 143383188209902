ul.gallery-slideshow > div, ul {margin: 0; padding: 0;}
ul.gallery-slideshow {
  position: relative;
  list-style: none;
  padding: 0;
  background: #e0e0e0;
  overflow: hidden;
  float: left;

  transition: transform 1s, opacity 1s;
  transform: scale(1);
  opacity: 1;
}

ul.gallery-slideshow li div {
  position: absolute;
  max-width: 100%; max-height: 100%;
}

.models-slideshow {
  width: 100%;
  height: 100%;
  display: inline-block;
  position: relative;
  background-color: transparent;
  overflow: hidden;
}

.models-slideshow:hover > a > p {
  opacity: 1;
  transition: opacity 1s;
}

.models-slideshow:hover > ul > a.ul > a.ul.gallery-slideshow {
  opacity: 0.5;
  transition: opacity 1.5s;
}

.gallery-slideshow:hover {
  opacity: 0.5;

  transform: scale(1.2);
  transform-origin: 50% 50%;

  transition: transform .75s ease-out, opacity .5s ease-out;
}